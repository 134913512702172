import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import { CheckCircleIcon } from "@heroicons/react/outline";

const FreezeMembershipPage = () => {
  return (
    <Layout>
      <Seo
        title="Job Application Submitted | FEAT Fitness"
        meta_title="Job Application Submitted | FEAT Fitness"
        slug="jobs/submitted"
        noindex
      />
      <main>
        <div>
          <div className="bg-feat-lightgrey">
            <div className="max-w-lg mx-auto py-24 px-6 text-base text-feat-darkgray-500 text-center">
              <CheckCircleIcon
                className="w-32 h-32 text-green-500 m-auto mb-8"
                aria-hidden="true"
              />
              Your job application was submitted. We'll be in touch shortly to
              let you know next steps.
            </div>
          </div>

          {/* Instagram Feed */}
          <InstagramFeed />
          {/* End Instagram Feed */}
        </div>
      </main>
    </Layout>
  );
};

export default FreezeMembershipPage;
